import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";

import { UserTitle } from "../../Components/NavBar/DashBoard/Settings";
import Plus from "../../Components/svgs/Plus";

import { TeamData } from "./TeamData";
import { AddTeamMember } from "./addTeamMember";

import { ContextType } from ".";

export const TeamsSettings: React.FC = () => {
    const props = useOutletContext<ContextType>();
    
    const [ addTeamsPopup, setAddTeamsPopup ] = useState<boolean>(false);

    let createMember = async ( name: string, email: string, phoneNumber: string, role: string ) => {
        await props.createTeamMember( name, email, phoneNumber, role );

        setAddTeamsPopup( false );
    }
    
    return (
        <div className='teams contents'>
            <AddTeamMember 
                active={addTeamsPopup} 
                setActive={ 
                    ( val: boolean ) => setAddTeamsPopup( val ) 
                } 
                createTeamMember={ 
                    ( name: string, email: string, phoneNumber: string, role: string ) => createMember( name, email, phoneNumber, role )
                } 
            />

            <div className='banner'>
                <img
                    src={props.organizer.banner}
                    alt="banner"
                    className="banner box-shadow"
                />
            </div>

            <div className='header-group'>
                <div className='header'>Members</div>

                <div className='button' onClick={ () => setAddTeamsPopup( true ) }>
                    <div className='icon'>
                        <Plus className='plus' />
                    </div>
                    <div className='text'>New Member</div>
                </div>
            </div>

            <div className='team-table box-shadow'>
                <div className='table-headers'>
                    <div className='th'>Name</div>
                    
                    <div className='th'>Email Address</div>
                    
                    <div className='th'>Account Type</div>
                    
                    <div className='th'>Joined Date</div>
                </div>

                <div className='table-content'>
                    { props.teams.map( d => (
                        <TeamData 
                            {...d}
                            isSession={ d.id === props.profile.id }
                            deleteMember={ () => props.deleteMember( d.id ) }
                            changeAccountType={ ( type: UserTitle ) => props.updateMember( d.id, type ) } 
                        /> 
                    ) ) }
                </div>
            </div>

            <div className='header-group'>
                <div className='header'>Pending Invites</div>
            </div>

            <div className='team-table box-shadow'>
                <div className='table-headers'>
                    <div className='th'>Name</div>
                    
                    <div className='th'>Email Address</div>
                    
                    <div className='th'>Account Type</div>
                    
                    <div className='th'>Joined Date</div>
                </div>

                <div className='table-content'>
                    { props.pendingTeams.map( d => ( 
                        <TeamData 
                            {...d} 
                            pending
                            isSession={ false }
                            resendMemberInvitation={ () => props.resendMemberInvitation( d.id ) }
                            deleteMember={ () => props.deleteMember( d.id ) } 
                            changeAccountType={ ( type: UserTitle ) => props.updateMember( d.id, type ) } 
                        /> 
                    )) }
                </div>
            </div>
        </div>
    )
}
