import React from "react";
import { useOutletContext } from "react-router-dom";

import FileUpload from "../../Components/svgs/FileUpload";
import ImageUploader from "../../Components/ImageUploader";

import { ContextType } from ".";

type OrganizerSettingsParams = {
    email: string;
    phoneNumber: string;
    location: string;
}

export const OrganizerSettings: React.FC = () => {
    const props = useOutletContext<ContextType>();
    
    return (
        <div className='org contents'>
            <div className='banner'>
                <ImageUploader 
                    preview={(
                        <img
                            src={props.organizer.banner}
                            alt="banner"
                            className="banner box-shadow"
                        />
                    )}
                    clickable={ props.organizer.editable }
                    handleImageUpload={props.organizer.uploadBanner}
                >
                    { props.organizer.editable && <div className='upload-banner'>Upload New Banner</div> }
                </ImageUploader>
            </div>

            <div className='organizer-info'>
                <div className="pic-container">
                    <div className='profile-cont'>
                        
                        <ImageUploader 
                            preview={(
                                <img src={props.organizer.picture} alt="profile" className="profile" />
                            )} 
                            clickable={ props.organizer.editable }
                            handleImageUpload={props.organizer.uploadProfilePicture}
                        >
                            { props.organizer.editable && <FileUpload className='icon' /> }
                        </ImageUploader>
                        
                    </div>

                    <div className="username">{props.organizer.name}</div>
                </div>

                {
                    props.organizer.editable && (
                        <div className="buttons">
                            <div className="reset" onClick={ () => props.organizer.changeOrganizerPassword() }>Reset Password</div>
                            <div className="save" onClick={ () => props.organizer.saveOrganizerSettings() } >Save</div>
                        </div>
                    )
                }
            </div>

            <div className='form-control box-shadow'>

                <div className="label">
                    <label htmlFor="orgName">Organizer Name</label>
                    <input
                        type="text"
                        className="form-control-input box-shadow"
                        name="orgName"
                        placeholder={props.organizer.name}
                        value={props.organizer.name}
                        readOnly={!props.organizer.editable}
                        onChange={ (e) => props.organizer.editOrganizer({ orgName: e.target.value })}
                    />
                </div>

                <div className="link">
                    <div className="label">
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            className="form-control-input box-shadow"
                            name="email"
                            placeholder={props.organizer.email}
                            value={props.organizer.email}
                            readOnly={!props.organizer.editable}
                            onChange={ (e) => props.organizer.editOrganizer({ email: e.target.value })}
                        />
                    </div>

                    <div className="label">
                        <label htmlFor="email">Phone Number</label>
                        <input
                            type="phone_number"
                            className="form-control-input box-shadow"
                            name="phone_number"
                            placeholder={props.organizer.phoneNumber}
                            value={props.organizer.phoneNumber}
                            readOnly={!props.organizer.editable}
                            onChange={ (e) => props.organizer.editOrganizer({ phoneNumber: e.target.value })}
                        />
                    </div>
                </div>

                <div className="label">
                    <label htmlFor="location">Location</label>
                    <input
                        type="text"
                        className="form-control-input box-shadow"
                        name="location"
                        placeholder={props.organizer.location}
                        value={props.organizer.location}
                        readOnly={!props.organizer.editable}
                        onChange={ (e) => props.organizer.editOrganizer({ location: e.target.value })}
                    />
                </div>
            </div>
        </div>
    )
}
