import './index.scss';

import React, { useState, useEffect } from "react";
import { BsFillArrowRightCircleFill as BS } from "react-icons/bs";
import UnSignedNavbar from '../../Components/NavBar/Unsigned';

import CustomAlert from '../../Components/CustomAlert';
import { MakeContactInput } from "../../utils";
import { useMakeContact } from "../../hooks";
import Loading from '../../Components/Loading';

const BsFillArrowRightCircleFill : any = BS;

const Sales: React.FC = () => {
    const [ props, setProps ] = useState<MakeContactInput>({ first_name: "", last_name: "", message: "", email: "", phone_number: "" });

    const { reload, makeContact, loading } = useMakeContact();
    const [alert, setAlert] = useState<{ message: string; type: 'success' | 'error' | 'info' } | null>(null);
    useEffect( () => {
        if ( reload === true ) window.location.reload();
    }, [ reload ]);
    
    const handleSubmit = async () => {
        // Validate props before sending
        if (
            props.first_name.length <= 0 ||
            props.last_name.length <= 0 ||
            props.phone_number.length <= 0 ||
            props.message.length <= 3 ||
            !props.email.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
        ) {
            setAlert({ message: "Please fill out the form correctly.", type: "error" });
            return;
        }

        await makeContact( props );

        setAlert({ message: "Your contact request was successfully sent!", type: "success" });
    };

    const closeAlert = () => {
        setAlert(null); // Clear the alert when closed
    };

    return (
        <div className="ReservePage">
            <UnSignedNavbar />

            <Loading loading={loading} />
            
            
            {alert && (
                <CustomAlert
                    message={alert.message}
                    type={alert.type}
                    visible={!!alert}
                    duration={10000}
                    close={closeAlert}
                />
            )}
            
            <div className="content">
                <img src="/sales-people.png" alt="page-food" className="page-food" />

                <div className="form-container box-shadow">
                    <div className="header">Contact Us</div>

                    <div className="sub-header">We will contact you as soon as possible regarding your reservation</div>

                    <div className="link">
                        <div className="label">
                            <label htmlFor="first_name">First Name</label>
                            <input type="text" className="form-control-input" name="first_name" 
                                onChange={ (e) => setProps( prev => ({ ...prev, first_name: e.target.value }))}
                            />
                        </div>

                        <div className="label">
                            <label htmlFor="last_name">Last Name</label>
                            <input type="text" className="form-control-input" name="last_name" 
                                onChange={ (e) => setProps( prev => ({ ...prev, last_name: e.target.value }))}
                            />
                        </div>
                    </div>

                    <div className="label">
                        <label htmlFor="email">Email</label>
                        <input type="email" className="form-control-input" name="email" 
                            onChange={ (e) => setProps( prev => ({ ...prev, email: e.target.value }))}
                        />
                    </div>

                    <div className="label">
                        <label htmlFor="phone_number">Phone Number</label>
                        <input type="telle" className="form-control-input" name="phone_number" 
                            onChange={ (e) => setProps( prev => ({ ...prev, phone_number: e.target.value }))}
                        />
                    </div>


                    
                    <div className="label">
                        <label htmlFor="message">Message</label>
                        <textarea className="form-control-input" name="message" 
                            onChange={ (e) => setProps( prev => ({ ...prev, message: e.target.value }))}
                        />
                    </div>

                    <div className="submit" onClick={handleSubmit}>
                        <div className="btn-text">Contact Now</div>
                        <BsFillArrowRightCircleFill className="btn-ico" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sales;
