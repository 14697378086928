import React, { useState } from 'react';
import { fetchGraphQl, getEventBannerUploadApi, loadEventDetails, modifyEventDetails } from '../../../utils';

export const useEventDetails = ( eventId: string ) => {
    const [ loading, setLoading ] = useState<boolean>(false);
        
    const [alert, setAlert] = useState<{ message: string; type: 'success' | 'error' | 'info' } | null>(null);

    const [ eventDetails, setEventDetails ] = useState<{
        id: string; title: string; banner: string;
        visibility: "Public" | "Private";
        views: number;
        dateCreated: number; eventDate: { startDate: number; endDate: number };
        description: string; location: string; totalTicketSold: number;
    }>({
        id: "", title: "", banner: "", visibility: "Private", views: 0, dateCreated: 0,
        eventDate: { startDate: 0, endDate: 0 }, description: "", location: "",
        totalTicketSold: 0
    });

    return {
        detailsLoading: loading,
        eventDetails,
        saveEventDetails: async (
            props: { 
                title?: string, banner?: string, visibility?: "Public" | "Private",
                description?: string, location?: string,
                eventDate?: { startDate: number; endDate: number }
            },

            token: string
        ) => {
            
            setLoading(true);

            setEventDetails( prev => ({ 
                ...prev,
                title: props.title || prev.title,
                banner: props.banner || prev.banner,
                visibility: props.visibility || prev.visibility,
                description: props.description || prev.description,
                location: props.location || prev.location,
                eventDate: props.eventDate || prev.eventDate
            }));

            let res = await fetchGraphQl( modifyEventDetails, {
                token,
                args: {
                    id: eventId,
                    title: props.title || eventDetails.title,
                    banner: props.banner || eventDetails.banner,
                    visible: ( props.visibility || eventDetails.visibility ) === "Public" ? true : false,
                    description: props.description || eventDetails.description,
                    location: props.location || eventDetails.location,
                    startDate: new Date( props.eventDate?.startDate || eventDetails.eventDate.startDate ),
                    endDate: new Date( props.eventDate?.endDate || eventDetails.eventDate.endDate )
                }
            });

            if ( res.errors ) {
                setLoading(false);
                setAlert({ message: "Failed to modify event details", type: "error" });
                return;
            }

            setAlert({ message: "Event details saved successfully", type: "success" });

            setLoading(false);
        },

        fetchEventDetails: async ( token: string ) => {
            if ( token === "" ) return;

            setLoading(true);

            const response = await fetchGraphQl( loadEventDetails, { eventId, token });

            if ( response.errors ) {
                setAlert({ message: "Failed to load event details", type: "error" });
            }

            const data = response.data.loadEventDetails;

            setEventDetails( data );

            setLoading( false );
        },

        uploadBanner: async ( img: File, token?: string ) => {
            if ( token === "" || token === undefined ) return;

            setLoading( true );

            const form = new FormData();

            form.append("image", img);
            form.append("token", token);
            form.append("eventId", eventId);

            const req = await fetch( getEventBannerUploadApi(), { method: "POST", body: form });

            let result = await req.json();

            if ( result.errors ) {
                setLoading(false);
                setAlert({ message: "Failed to upload banner", type: "error" });

                return { response: 404 };
            }

            if ( result.response ) {
                setEventDetails( prev => ({ ...prev, banner: result.response }));
                setLoading( false );
            }

            setAlert({ message: "Banner uploaded successfully", type: "success" });

            return { response: 200, token };
        },

        eventAlert: alert,
        eventSetAlert: setAlert
    }
}
