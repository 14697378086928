import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { createEvent, fetchGraphQl, loadAllEvents } from '../../utils';

export type AllEventsPageResponse = {
    id: string;
    title: string;
    banner: string;
    visibility: "Public" | "Private";
    views: number;
    dateCreated: number;
    eventDate: {
        startDate: number;
        endDate: number;
    }
}

export const useEvents = () => {

    const navigate = useNavigate();
    
    let [ data, setData ] = useState<AllEventsPageResponse[]>([]);

    const [ lastIndex, setLastIndex ] = useState<number>(0);
    
    const [ loadMore, setLoadMore ] = useState<boolean>(true);

    let [ loading, setLoading ] = useState<boolean>( false );

    const [alert, setAlert] = useState<{ message: string; type: 'success' | 'error' | 'info' } | null>(null);


    const [ createEventActive, setCreateEventActive ] = useState<boolean>(false);
    const [ error, setError ] = useState<string | null>(null);
    const [ title, setTitle ] = useState<string>('');
    const [ description, setDescription ] = useState<string>('');

    const pageSize = 7;

    const paginationRequest = async ( token: string ) => {
        if ( token === "" ) return;

        setLoading( true );

        const response = await fetchGraphQl(loadAllEvents, {
            token,
            pageLength: pageSize,
            lastIndex,
        });

        if(response.errors) {
            console.error("Error loading events:", response.errors);
            setError("Failed to load events");
            return;
        }

        const {
            events,
            endIndex,
            loadMore: canLoadMore
        } = response.data.loadAllEventsPage;

        setData((prev) => [...prev, ...events]);
        
        setLastIndex(endIndex);
        
        setLoadMore(canLoadMore);

        setLoading( false );

        return {
            endIndex,
            loadMore: canLoadMore
        };
    }

    return  {
        createEventActive,
        setCreateEventActive,
        loading,
        data,
        loadMore,
        paginationRequest,
        setTitle,
        setDescription,
        error,

        createEvent: async ( token: string ) => {
            if ( title.length <= 0 || description.length <= 0 ) return setError("Please fill out form.");

            // Send to server

            setLoading( true );

            let res = await fetchGraphQl(createEvent, { token, title, description })

            if ( res.errors ) return setAlert({ message: "Problem creating event", type: "error"});

            setError( null );
            setLoading( false );

            navigate(`/events/${res.data.createEvent.id}`);
            
        },

        alert,
        closeAlert: ( ) => setAlert(null) // Clear the alert when closed
    }
}
