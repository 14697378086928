import "./index.scss";

import { useState, useEffect } from "react";
import PopUp from "../PopUp";
import LoadingSP from "../svgs/LoadingSpinner";

type Props = {
  loading: boolean;
}

export const Loading : React.FC<Props> = ({ loading }) => {
  const [active, setActive] = useState<boolean>(false);

  const setActiveAsLoading = () => {
    setActive(loading);
  };

  useEffect(() => {
    setActive( loading );
  }, [ loading ]);

  return loading ? (
    <div>
      <PopUp active={active} setActive={setActiveAsLoading}>
        <div className="centerPopUp">
          <LoadingSP />
        </div>
      </PopUp>
    </div>
  ) : (
    <div></div>
  );
}

export default Loading;
