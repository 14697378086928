import './index.scss';
import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { EventDetailsContextType } from '..';
import UnReleased from '../../../Components/UnReleased';

const EventAnalytics: React.FC = () => {
    const { eventId } = useOutletContext<EventDetailsContextType>();

    return (
        <div className='event-analytics-content'>
            <UnReleased date={new Date("03/10/2025")} />
        </div>
    )
}

export default EventAnalytics;
