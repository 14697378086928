import './index.scss';

import React, { useState } from 'react';
import Trashcan from '../../../svgs/Trashcan';
import PopUp from '../../../PopUp';
import Download from '../../../svgs/Download';

import { downloadImage } from '../../../../utils';

type EventGalleryProps = {
    url: string;
    deleteGallery: () => void;
}

const EventGallery: React.FC<EventGalleryProps> = ({ url, deleteGallery}) => {
    const [ active, setActive ] = useState<boolean>(false);

    return (
        <div className='event-gallery box-shadow' >
            <img src={url} alt='gallery' onClick={ () => setActive( !active ) } />
            <Trashcan onClick={ deleteGallery } className='trashcan' />

            <PopUp active={active} setActive={ val => setActive( val ) }>
                <img src={url} alt='gallery' className='popup-img box-shadow' />
                <Download className='download' onClick={ () => downloadImage(url, `CravingsInc-Event-Gallery.${ ( new URL( url ).pathname.split('.').pop()?.toLowerCase() )}`) }/>
            </PopUp>
        </div>
    )
}

export default EventGallery;
