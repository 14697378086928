import React, { useState, useEffect } from 'react';
import { createEventTicket, fetchGraphQl, getSalesPage, modifyEventTicket } from '../../../utils';

export type TicketCreateDetails = {
    title: string;
    amount: number;
    description: string;
}

export type TicketModifyDetails = { 
    id: string; 
    totalTicketAvailable: number; 
} & TicketCreateDetails;

export type TicketDetails = {
    id: string;
    title: string;
    ticketSold: number;
    totalTickets: number;
    ticketPrice: number;
    description: string;
}

export type SalesDetails = {
    id: string;
    name: string;
    amount: number;
    dateCreated: number;
    currency: string;
    checkIn: {
        checkIn: boolean;
        date: number;
    };
    completed: {
        completed: boolean;
        date: number;
    };
    tickets: {
        id: string;
        title: string;
        description: string;
        quantity: number;
        price: number;
    }[]
}

export const useEventSales = ( eventId: string ) => {
    const [ loading, setLoading ] = useState<boolean>(false);

    const [ tokenState, setToken ] = useState<string>("");

    const [alert, setAlert] = useState<{ message: string; type: 'success' | 'error' | 'info' } | null>(null);

    const [ tickets, setTickets ] = useState<TicketDetails[]>([]);

    const [ sales, setSales ] = useState<SalesDetails[]>([]);

    return {

        salesLoading: loading,
        salesAlert: alert,
        salesSetAlert: setAlert,
        tickets,
        sales,

        fetchSalesDetails: async ( token: string ) => {
            if ( !token ) return;

            setLoading( true );

            // fetch sales details here

            let res = await fetchGraphQl( getSalesPage, { eventId, token });

            if ( res.errors ) {
                setLoading( false );
                return setAlert({ message: "Failed to fetch sales details", type: 'error' });
            }

            setTickets( res.data.getSalesPage.tickets );
            setSales( res.data.getSalesPage.sales );

            setLoading( false );

            setToken( token );
        },

        modifyEventTickets: async ( props: TicketModifyDetails ) => {
            setLoading( true );

            let res = await fetchGraphQl( modifyEventTicket, { 
                token: tokenState,
                eventId, 
                args: {
                    id: props.id,
                    title: props.title,
                    amount: props.amount,
                    description: props.description,
                    totalTicketAvailable: props.totalTicketAvailable,
                    currency: 'usd'
                }
            });

            if ( res.errors ) {
                setLoading( false );
                return setAlert({ message: "Failed to modify ticket", type: 'error' });
            }

            setTickets( prev => {
                return prev.map( ticket => {
                    if ( ticket.id === props.id ) {
                        return {
                            ...ticket,
                            title: props.title,
                            ticketPrice: props.amount,
                            description: props.description,
                            totalTickets: props.totalTicketAvailable
                        }
                    }

                    return ticket;
                })
            });

            setAlert({ message: "Ticket modified successfully", type: 'success' });

            setLoading( false );
        },

        createEventTicket: async (props: TicketCreateDetails ) => {
            setLoading( true );

            let res = await fetchGraphQl( createEventTicket,  { 
                amount: props.amount, title: props.title, eventId, 
                token: tokenState, currency: 'usd', description: props.description 
            });

            if ( res.errors ) {
                setLoading( false );
                return setAlert({ message: "Failed to create ticket", type: 'error' });
            }

            setTickets( prev => {
                return [
                    ...prev,
                    {
                        id: res.data.createEventTicket,
                        title: props.title,
                        ticketSold: 0,
                        totalTickets: 45,
                        ticketPrice: props.amount,
                        description: props.description
                    }
                ]
            })

            setAlert({ message: "Ticket created successfully", type: 'success' });

            setLoading( false );
        }
    }
}
