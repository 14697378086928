import './index.scss';
import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { EventDetailsContextType } from '..';
import { SalesCard } from './SalesCard';
import Ticket from '../../../Components/svgs/Ticket';
import Plus from '../../../Components/svgs/Plus';
import { TicketCard } from './TicketCard';
import { AddTicket } from './AddTicket';

const EventSales: React.FC = () => {
    const { eventId, sales, loading } = useOutletContext<EventDetailsContextType>();
    const [ active, setActive ] = useState<boolean>(false);

    return (
        <div className='event-sales-content'>
            <div className='content c1 box-shadow'>
                <div className='title'>Ticket Sales</div>

                <div className='table-container'>
                    <div className='table main-table'>
                        <div className='row'>
                            <div className='col'>
                                <div className='name'>Name</div>
                                <div className='amount'>Amount</div>
                                <div className='date'>Date</div>
                            </div>
                        </div>
                    </div>

                    <div className='table'>
                        {
                            loading ? 
                            <div className='loading'>Loading...</div>
                            :
                                sales.sales.length === 0 ? 
                                    <div className='empty'>No Ticket Purchased Yet</div> : 
                                    sales.sales.map( ( event, index ) => (
                                        <SalesCard {...event} lastIndex={ sales.sales.length - 1 === index } />
                                    ))
                        }
                    </div>
                </div>
            </div>

            <div className='content c2'>
                <div className='label-container sales form-2 box-shadow'>
                    <div className='label'>Ticket Sales</div>

                    <div className='container'>
                        <Ticket className='ticket' />
                        <div className='text'>{sales.sales.reduce( ( sum, sale ) => 
                            sale.tickets.reduce( (s, t ) => s + t.quantity, 0 ) + sum, 0 )} Tickets Sold</div>
                    </div>
                </div>

                <div className='label-container sales form-2 box-shadow'>
                    <div className='label'>Ticket Revenue</div>

                    <div className='container'>
                        <Ticket className='ticket' />
                        <div className='text'>${sales.sales.reduce( ( sum, sale ) => sale.amount + sum, 0 ) } Tickets Sold</div>
                    </div>
                </div>

                <div className='ticket-container box-shadow'>
                    <div className='header'>
                        <div className='label'>Tickets Available</div>

                        <div className='btn' onClick={ () => setActive( true ) }>
                            <Plus />
                            <div className='text'>Create Ticket</div>
                        </div>
                    </div>

                    <div className='ticketcard-container'>
                        {
                            sales.tickets.length === 0 ? <div className='empty'>No Ticket Purchased Yet</div> : sales.tickets.map( ( ticket ) => (
                                <TicketCard {...ticket} createEventTicket={sales.createEventTicket} modifyEventTicket={sales.modifyEventTickets} />
                            ))
                        }
                    </div>
                </div>
            </div>

            <AddTicket active={active} setActive={setActive} createEventTicket={sales.createEventTicket} modifyEventTicket={sales.modifyEventTickets} />
        </div>
    )
}

export default EventSales;
