import './index.scss';

import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { EventDetailsContextType } from '..';

import Plus from '../../../Components/svgs/Plus';
import Gallery from '../../../Components/pages/events/EventGallery';
import ImageUploader from '../../../Components/ImageUploader';

const EventGallery: React.FC = () => {
    const { eventId, gallery } = useOutletContext<EventDetailsContextType>();

    return (
        <div className='event-gallery-content'>
            <div className='header'>
                <ImageUploader
                    preview={(
                        <div className='button'>
                            <Plus className='plus' />
                            <div className='text'>Add To Gallery</div>
                        </div>
                    )}
                    clickable={ true }
                    handleImageUpload={ async ( img: File ) => await gallery.uploadPhoto(img) }
                />
            </div>

            <div className='gallery-container'>
                {
                    gallery.gallery.map( ( photo, i ) => <Gallery url={photo.url} deleteGallery={ () => gallery.deletePhoto( photo.id ) } key={i} /> )
                }

                { gallery.loadMore && <div className='loadmore' onClick={ () => gallery.paginationRequest("") }>Load More</div> }
            </div>
        </div>
    );
}

export default EventGallery;
