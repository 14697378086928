import './index.scss';

import React, { useState, useEffect } from 'react';
import ArrowLeft from '../svgs/ArrowLeft';

type DropDownProps = {
    options: { val: any, text: string, jsx: React.ReactNode }[];
    placeholder: React.ReactNode;
    onChange: ( selectedOption: { val: any, text: string, jsx: React.ReactNode } ) => void;

    dropDownClasses?: {
        container?: string;
        selector?: string;
        placeholder?: string;
        arrow?: string;
        optContainers?: string;
        options?: string;
    };
}

const DropDownJsx: React.FC<DropDownProps> = ({ options, placeholder, onChange, dropDownClasses }) => {
    const [ currentState, setCurrentState ] = useState<{ val: any, text: string, jsx: React.ReactNode } | undefined>();

    const [ selected, setSelected ] = useState<boolean>(false);

    useEffect( ( ) => {

        if ( currentState === undefined ) return;

        setSelected( false );
        onChange( currentState );

    }, [ currentState ])
    
    return (
        <div className={`dropdown-container ${dropDownClasses?.container}`}>
            <div className={`selector box-shadow ${dropDownClasses?.selector}`} onClick={ () => setSelected( !selected ) }>
                <div className={`placeholder ${dropDownClasses?.placeholder}`}>{ currentState === undefined ? placeholder : currentState.jsx }</div>
                <ArrowLeft className={`arrow ${dropDownClasses?.arrow} ${selected ? 'active' : '' }`} />
            </div>

            {
                selected && (
                    <div className={`options-container ${dropDownClasses?.optContainers}`}>
                        { options.map( op => <div className={`options box-shdaow ${dropDownClasses?.options}`} onClick={ () => setCurrentState( op )}>{ op.jsx }</div>) }
                    </div>
                )
            }
        </div>
    );
}

export default DropDownJsx;
