export const createEventTicket = `
mutation CreateEventTicket($amount: Float!, $title: String!, $eventId: String!, $token: String!, $currency: String, $description: String) {
  createEventTicket(amount: $amount, title: $title, eventId: $eventId, token: $token, currency: $currency, description: $description)
}
`;

export const modifyEventTicket = `
mutation modifyEventTicket($args: ModifyEventTicketInputType!, $token: String!, $eventId: String!) {
  modifyEventTicket(args: $args, token: $token, eventId: $eventId)
}
`;

export const getSalesPage = `
query GetSalesPage($eventId: String!, $token: String!) {
  getSalesPage(eventId: $eventId, token: $token) {
    tickets {
      id
      title
      description
      totalTickets
      ticketSold
      ticketPrice
    }
    sales {
      id
      name
      amount
      dateCreated
      currency
      checkIn {
        checkIn
        date
      }
      completed {
        completed
        date
      }
      tickets {
        id
        title
        description
        quantity
        price
      }
    }
  }
}
`;
