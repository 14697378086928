import React, { useState } from 'react';
import { AddTicket } from './AddTicket';
import { TicketCreateDetails, TicketModifyDetails } from '../../../hooks/page/EventDetails/sales';

type Prop = {
    id: string;
    title: string;
    ticketSold: number;
    totalTickets: number;
    ticketPrice: number;
    description: string;
    createEventTicket: ( ticket: TicketCreateDetails ) => Promise<void>;
    modifyEventTicket: ( ticket: TicketModifyDetails ) => Promise<void>;
};

export const TicketCard: React.FC<Prop> = (prop) => {
    const [ active, setActive ] = useState<boolean>(false);

    return (
        <>
            <div className='ticketCard' onClick={ () => setActive( true ) }>
                <div className='title'>{prop.title}</div>

                <div className='wrapper'>
                    <div className='card box-shadow'>${prop.ticketPrice}</div>

                    <div className='card box-shadow'>{prop.ticketSold} Tickets Sold</div>
                    
                    <div className='card box-shadow'>{prop.totalTickets - prop.ticketSold} Left</div>
                </div>
            </div>

            <AddTicket 
                active={active} 
                setActive={ ( val: boolean ) => {
                    setActive( val )
                } }
                edit={{
                    id: prop.id,
                    title: prop.title,
                    amount: prop.ticketPrice,
                    description: prop.description,
                    totalTicketAvailable: prop.totalTickets
                }}

                createEventTicket={prop.createEventTicket} 
                modifyEventTicket={prop.modifyEventTicket}
            />
        </>
    )
}
