import React, { useState } from "react";

import Trashcan from "../../Components/svgs/Trashcan";
import ArrowLeft from "../../Components/svgs/ArrowLeft";
import { UserTitle } from "../../Components/NavBar/DashBoard/Settings";
import Resend from "../../Components/svgs/Send";
import DropDown from "../../Components/DropDown";

import { formatEventDate, OrganizerSettingsPageTeams } from "../../utils";

type TeamDataProps = {
  deleteMember: () => void;
  changeAccountType: (type: UserTitle) => void;
  pending?: boolean;
  isSession: boolean;
  resendMemberInvitation?: () => void;
} & OrganizerSettingsPageTeams;

export const TeamData: React.FC<TeamDataProps> = ({
  name,
  type,
  email,
  profilePicture,
  joinedDate,
  deleteMember,
  changeAccountType,
  pending,
  isSession,
  resendMemberInvitation,
}) => {
  let dateFormat = formatEventDate(new Date(joinedDate));

  if (dateFormat.indexOf("@") !== -1) {
    dateFormat =
      dateFormat.substring(0, dateFormat.indexOf("@") - 1) +
      ", " +
      new Date(joinedDate).getFullYear();
  }

  const updateMember = async (type: UserTitle) => {
    changeAccountType(type);
  };

  const customClassesDropdown = {
    container: "dropDownClassTeamData",
    selector: "",
    placeholder: "",
    arrow: "",
    options: "dropDownClassTeamDataOptions",
  };

  return (
    <div className="team-data">
      <div className="th">
        <img src={profilePicture} className="profile" alt="profile" />

        <div className="name">{name}</div>

        {pending && (
          <div
            className="resend"
            title="Resend Invitation"
            onClick={resendMemberInvitation}
          >
            <Resend className="icon" />
          </div>
        )}

        {type !== "Organizer" && !isSession && (
          <div className="trash" onClick={deleteMember} title="Delete Member">
            <Trashcan className="icon" />
          </div>
        )}
      </div>

      <div className="th">{email}</div>

      <DropDown
        options={ 
          type !== "Organizer" ? 
            [ 'Admin', 'Member', 'Guest' ].map( op => ({ val: op, text: op }) )
          : []
        }
        placeholder={type}
        onChange={(selected) => updateMember(selected.val)}
        dropDownClasses={customClassesDropdown}
      />

      <div className="th">{dateFormat}</div>
    </div>
  );
};
