import './index.scss';
import React, { useState, useEffect } from 'react';
import DropDown, { DropDownOptions } from '../DropDown/index';

// Define the type for the return structure
type DateProp = {
    day: number;
    month: number;
    year: number;
};

const startYear = 2020; // start year
const endYear = new Date().getFullYear() + 5; // Current year

type DateSelectorProps = {
    onDateChange: (date: Date) => void;
    defaultDate?: number;
    label?: string;
};

const DateSelector: React.FC<DateSelectorProps> = ({ onDateChange, label, defaultDate }) => {
    
    let defaultDateObj = new Date(  defaultDate ?  defaultDate : new Date() );

    const [date, setDate] = useState<DateProp>({ day: defaultDateObj.getDate(), month: defaultDateObj.getMonth() + 1, year: defaultDateObj.getFullYear() });
    const [dayOptions, setDayOptions] = useState<{ val: number; text: string }[]>([]);

    const handleChange = (part: string, selectedOption: { val: any; text: string }) => {
        setDate((prevDate) => ({
            ...prevDate,
            [part]: selectedOption.val,
        }));
    };

    // Update day options when month or year changes
    useEffect(() => {
        if ( date.month > 0 ) {
            const daysInMonth = new Date(date.year, date.month, 0).getDate();

            const updatedDayOptions = Array.from({ length: daysInMonth }, (_, i) => ({
                val: i + 1,
                text: (i + 1).toString(),
            }));

            setDayOptions(updatedDayOptions);

            // Reset day if it's invalid for the new month/year
            if (date.day > daysInMonth) {
                setDate((prev) => ({ ...prev, day: 0 }));
            }
        }
    }, [date.month, date.year]);

    useEffect( () => {
        onDateChange( new Date( 
            date.year, date.month - 1, date.day, 
            defaultDateObj.getHours(), defaultDateObj.getMinutes(), 
            defaultDateObj.getSeconds(), defaultDateObj.getMilliseconds() 
        ) );
    }, [ date ])

    const monthOptions = Array.from({ length: 12 }, (_, i) => ({
        val: i + 1,
        text: new Date(0, i).toLocaleString('default', { month: 'short' }),
    }));

    const yearOptions = Array.from({ length: endYear - startYear + 1 }, (_, i) => ({
        val: startYear + i,
        text: (startYear + i).toString(),
    }));

    const dropDownClass = {
        selector: 'date-selector',
        options: 'date-options',
        optContainers: 'date-options-container'
    }

    return (
        <div className='date-selector-container box-shadow'>
            <p className='label'>{label}</p>
            <div className='dropdowns'>
                <DropDown
                    options={monthOptions}
                    placeholder={ monthOptions.find( op => op.val === ( defaultDateObj.getMonth() + 1 ) )?.text || '' }
                    onChange={(selectedOption) => handleChange('month', selectedOption)}
                    dropDownClasses={ dropDownClass }
                />
                <DropDown
                    options={dayOptions}
                    placeholder={ dayOptions.find( op => op.val === defaultDateObj.getDate() )?.text || '' }
                    onChange={(selectedOption) => handleChange('day', selectedOption)}
                    dropDownClasses={ dropDownClass }
                />
                <DropDown
                    options={yearOptions}
                    placeholder={ yearOptions.find( op => op.val === defaultDateObj.getFullYear() )?.text || '' }
                    onChange={(selectedOption) => handleChange('year', selectedOption)}
                    dropDownClasses={ dropDownClass }
                />
            </div>
        </div>
    );
};

export default DateSelector;
