import React from "react";

type GalleryProps = {
  fill?: string;
  stroke?: string
  className?: string;
};

const Gallery: React.FC<GalleryProps> = ({ fill, className, stroke }) => {
  return (
    <svg width="800px" height="800px" className={className} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">

      <g id="SVGRepo_bgCarrier" stroke-width="0"/>

      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>

      <g id="SVGRepo_iconCarrier"> 
        <path d="M9 10C10.1046 10 11 9.10457 11 8C11 6.89543 10.1046 6 9 6C7.89543 6 7 6.89543 7 8C7 9.10457 7.89543 10 9 10Z" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> 
        <path d="M12 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V11" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> 
        <path d="M16.12 5.1099C15.79 4.0699 16.18 2.7799 17.26 2.4299C17.83 2.2499 18.54 2.3999 18.94 2.9499C19.32 2.3799 20.05 2.2399 20.62 2.4299C21.71 2.7799 22.1 4.0699 21.77 5.1099C21.25 6.7499 19.45 7.6099 18.95 7.6099C18.44 7.6099 16.65 6.7699 16.12 5.1099Z" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> 
        <path d="M2.67004 18.9501L7.60004 15.6401C8.39004 15.1101 9.53004 15.1701 10.24 15.7801L10.57 16.0701C11.35 16.7401 12.61 16.7401 13.39 16.0701L17.55 12.5001C18.33 11.8301 19.59 11.8301 20.37 12.5001L22 13.9001" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> 
      </g>

    </svg>
  );
};

export default Gallery;
