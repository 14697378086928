export const createEvent = `mutation CreateEvent($description: String!, $title: String!, $token: String!) {
    createEvent(description: $description, title: $title, token: $token) {
      id
    }
}`;

export const loadAllEvents = `query LoadAllEventsPage($token: String!, $pageLength: Float!, $lastIndex: Float!) {
  loadAllEventsPage(token: $token, pageLength: $pageLength, lastIndex: $lastIndex) {
    endIndex
    loadMore
    events {
      id
      title
      banner
      visibility
      views
      dateCreated
      eventDate {
        startDate
        endDate
      }
    }
  }
}`;

export const loadEventDetails = `
  query loadEventDetails( $eventId: String!, $token: String! ) {
    loadEventDetails(eventId: $eventId, token: $token) {
      id
      title
      banner
      visibility
      views
      dateCreated
      eventDate {
        startDate
        endDate
      }
      description
      location
      totalTicketSold
    }
  }
`;

export const loadAllEventsGalleryPage =  `
  query loadAllEventsGalleryPage($lastIndex: Float!, $pageLength: Float!, $eventId: String!, $token: String!) {
    loadAllEventsGalleryPage(lastIndex: $lastIndex, pageLength: $pageLength, eventId: $eventId, token: $token) {
      endIndex
      loadMore
      gallery {
        id
        url
        dateUploaded
      }
    }
  }
`;

export const modifyEventDetails = `
mutation ModifyEvent($args: ModifyEventInputType!, $token: String!) {
  modifyEvent(args: $args, token: $token)
}
`;

export const deletePhotoId = `
mutation DeletePhotoGallery($photoId: String!, $token: String!) {
  deletePhotoGallery(photoId: $photoId, token: $token)
}
`;
