import React, { useState } from 'react';
import PopUp from '../../../Components/PopUp';
import { TicketCreateDetails, TicketModifyDetails } from '../../../hooks/page/EventDetails/sales';

type Props = {
    active: boolean;
    setActive: ( val: boolean ) => void;
    createEventTicket: ( ticket: TicketCreateDetails ) => Promise<void>;
    modifyEventTicket: ( ticket: TicketModifyDetails ) => Promise<void>;
    edit?: {
        id: string;
        title: string;
        amount: number;
        totalTicketAvailable: number;
        description: string;
    }
}

export const AddTicket: React.FC<Props> = ({ active, setActive, edit, modifyEventTicket, createEventTicket }) => {
    const [ title, setTitle ] = useState<string>(edit?.title || '');
    const [ amount, setAmount ] = useState<number>(edit?.amount || 0);
    const [ totalTicketAvailable, setTotalTicketAvailable ] = useState<number>(edit?.totalTicketAvailable || 0);
    const [ description, setDescription  ] = useState<string>(edit?.description || '');

    const handleSubmit = async () => {
        if ( edit ) {
            await modifyEventTicket({ id: edit.id, title, amount, totalTicketAvailable, description });
        }else {
            await createEventTicket({ title, amount, description });
        }

        setActive( false );
    }

    return (
        <PopUp active={active} setActive={setActive}>
            <div className='create-ticket-popup'>
                <div className='title'>{ edit ? "Modify" : "Create"} Ticket</div>

                <div className='form-popup'>
                    <div className='label-container box-shadow'>
                        <div className='label'>Title { edit ? "" : "( required )"}</div>
                        <input type="text" className="form-control-input" name="name" placeholder={title} 
                            onChange={ e => setTitle( e.target.value )} value={title}
                        />    
                    </div>

                    <div className='label-container box-shadow'>
                        <div className='label'>Amount { edit ? "" : "( required )"}</div>
                        <input type="number" className="form-control-input" name="name" placeholder={String(amount)} 
                            onChange={ e => setAmount( Number(e.target.value) )} value={amount}
                        />    
                    </div>

                    {
                        edit && (
                            <div className='label-container box-shadow'>
                                <div className='label'>Total Ticket Available { edit ? "" : "( required )"}</div>
                                <input type="number" className="form-control-input" name="name" placeholder={String(totalTicketAvailable)} 
                                    onChange={ e => setTotalTicketAvailable( Number(e.target.value) )} value={totalTicketAvailable}
                                />    
                            </div>
                        )
                    }

                    <div className='label-container box-shadow'>
                        <div className='label'>Description { edit ? "" : "( required )"}</div>
                        <textarea className="form-control-input textarea" name="name" placeholder={description} 
                            onChange={ e => setDescription( e.target.value )} value={description}
                        />   
                    </div>
                </div>

                <div className='button' onClick={ handleSubmit }>{ edit ? "Modify" : "Create" } Ticket</div>
            </div>
        </PopUp>
    )
}
