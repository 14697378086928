import React from "react";

type PenProps = {
  fill?: string;
  className?: string;
  stroke?: string;
  onClick?: (e: any) => void;
};

const Pen: React.FC<PenProps> = ({ fill, className, stroke }) => {
  return (
    <svg fill={fill} className={className} width="800px" height="800px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">

      <g id="SVGRepo_bgCarrier" stroke-width="0"/>

      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>

      <g id="SVGRepo_iconCarrier">

        <path d="M20.7,5.2a1.024,1.024,0,0,1,0,1.448L18.074,9.276l-3.35-3.35L17.35,3.3a1.024,1.024,0,0,1,1.448,0Zm-4.166,5.614-3.35-3.35L4.675,15.975,3,21l5.025-1.675Z"/>

      </g>

    </svg>
  );
};

export default Pen;
