import './index.scss';

import React, { useState, useEffect } from 'react';
import DropDownJsx from '../DropDownJsx';

export type DropDownOptions = { val: any, text: string };

type DropDownProps = {
    options: DropDownOptions[];
    placeholder: string;
    onChange: ( selectedOption: { val: any, text: string } ) => void;

    dropDownClasses?: {
        container?: string;
        selector?: string;
        placeholder?: string;
        arrow?: string;
        optContainers?: string;
        options?: string;
    };
}

const DropDown: React.FC<DropDownProps> = ({ options, placeholder, onChange, dropDownClasses }) => {
    const [ currentState, setCurrentState ] = useState<{ val: any, text: string } | undefined>();

    useEffect( ( ) => {

        if ( currentState === undefined ) return;
        
        onChange( currentState );

    }, [ currentState ])
    
    return (

        <DropDownJsx
            options={ 
                options.map( op => ({ val: op.val, text: op.text, jsx: <>{op.text}</> }) )
            }
            placeholder={ <>{placeholder}</> }
            onChange={ ( selectedOption: { val: any, text: string, jsx: React.ReactNode } ) => setCurrentState( selectedOption ) }
            dropDownClasses={ dropDownClasses }
        />
    );
}

export default DropDown;
