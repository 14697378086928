import React, { useState } from 'react';

import { formatNumber, formatEventDate } from '../../../utils';
import PopUp from '../../../Components/PopUp';
import Resend from '../../../Components/svgs/Resend';
import { SalesDetails } from '../../../hooks/page/EventDetails/sales';

type Props = {
    lastIndex: boolean;
} & SalesDetails;

export const SalesCard: React.FC<Props> = (props) => {
    const [ active, setActive ] = useState<boolean>(false);

    return (
        <>
            <div className='row salesCard' onClick={ () => setActive( true ) }>
                <div className="col">
                    <div className="name">{props.name}</div>

                    <div className="amount">{formatNumber(props.amount)}</div>

                    <div className="date">
                        {formatEventDate(new Date(props.dateCreated))}
                    </div>
                </div>

                {!props.lastIndex && <div className="divider" />}
            </div>

            <PopUp active={active} setActive={ ( val: boolean ) => setActive( val ) }>
                <div className='sales-card-popup'>
                    <div className='header'>
                        <div className='title'>${props.amount} {props.currency.toLocaleUpperCase()}</div>
                        {
                            props.amount > 0 && false && (
                                <div className='icon-btn'>
                                    <Resend />
                                    <div className='text'>Refund</div>
                                </div>
                            )
                        }
                    </div>

                    <div className='timeline'>
                        <div className='title'>Timeline</div>

                        <div className='divider' />

                        <div className='container'>
                            {
                                props.checkIn.checkIn && (
                                    <div className='timeline-card'>
                                        <div className='date-card'>
                                            <div className='bubble' />
                                        </div>

                                        <div className='content'>
                                            <div className='title'>Checked In</div>
                                            <div className='date'>{formatEventDate(new Date(props.checkIn.date))}</div>
                                        </div>
                                    </div>
                                )   
                            }

                            {
                                props.completed.completed && (
                                    <div className='timeline-card'>
                                        <div className='date-card'>
                                            <div className='bubble first' />

                                            {
                                                props.checkIn.checkIn && (
                                                    <div className='line-connector' />
                                                )
                                            }
                                        </div>

                                        <div className='content'>
                                            <div className='title'>Payment succeeded</div>
                                            <div className='date'>{formatEventDate(new Date(props.completed.date))}</div>
                                        </div>
                                    </div>
                                )   
                            }
                        </div>
                    </div>

                    <div className='payment-breakdown'>
                        <div className='title'>Payment Breakdown</div>

                        <div className='divider' />

                        <div className='container'>
                            <div className='payment-breakdown-card'>
                                <div className='title'>Payment Amount</div>
                                <div className='amount'>${formatNumber(props.amount)} {props.currency.toLocaleUpperCase()}</div>
                            </div>

                            <div className='divider' />

                            <div className='payment-breakdown-card'>
                                <div className='title'>CravingsInc Application Fee</div>
                                <div className='amount'>${ formatNumber(props.amount * 0.1) } {props.currency.toLocaleUpperCase()}</div>
                            </div>

                            <div className='divider' />

                            <div className='payment-breakdown-card bold'>
                                <div className='title'>Net Amount</div>
                                <div className='amount'>${ formatNumber(props.amount * 0.9) } {props.currency.toLocaleUpperCase()}</div>
                            </div>
                        </div>
                    </div>

                    <div className='payment-breakdown'>
                        <div className='title'>Ticket Breakdown</div>

                        <div className='divider' />

                        <div className='container'>
                            {
                                props.tickets.map( ( ticket, index ) => (
                                    <>
                                        <div key={index} className='payment-breakdown-card'>
                                            <div className='title'>{ticket.title}</div>
                                            <div className='amount'>{formatNumber(ticket.quantity * ticket.price)} {props.currency.toLocaleUpperCase()} x {ticket.quantity}</div>
                                        </div>

                                        <div className='divider' />
                                    </>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </PopUp>
        </>
    )
}
