import React, { useState, useEffect } from 'react';
import { deletePhotoId, fetchGraphQl, getGalleryUploadApi, loadAllEventsGalleryPage } from '../../../utils';

export const useEventGallery = ( eventId: string ) => {
    const [ loading, setLoading ] = useState<boolean>(false);

    const [ tokenState, setToken ] = useState<string>("");

    const [ lastIndex, setLastIndex ] = useState<number>(0);
    const [ loadMore, setLoadMore ] = useState<boolean>(false);

    const [ gallery, setGallery ] = useState<{ id: string, url: string, dateUploaded: number }[]>([]);

    
    const [alert, setAlert] = useState<{ message: string; type: 'success' | 'error' | 'info' } | null>(null);

    const pageSize = 6;

    const galleryPaginationRequest = async ( token: string ) => {
        if ( token === "" || ( token === "" && tokenState === "") ) return;

        setLoading( true );

        const response = await fetchGraphQl( loadAllEventsGalleryPage, { token, eventId, pageLength: pageSize, lastIndex } );

        if ( response.errors ) {
            setAlert({ message: "Failed to load gallery", type: 'error' });
        }

        const {
            gallery,
            endIndex,
            loadMore: canLoadMore
        } = response.data.loadAllEventsGalleryPage;

        setGallery( prev => [ ...prev, ...gallery ]);

        setLastIndex( endIndex );

        setLoadMore( canLoadMore );

        setLoading( false );

        setToken( token ); // succesfully loaded means token is correct

        return {
            endIndex,
            loadMOre: canLoadMore
        }
    }

    return {
        galleryLoading: loading,
        galleryLastIndex: lastIndex,
        galleryLoadMore: loadMore,
        gallery,
        galleryPaginationRequest,

        deletePhoto: async ( photoId: string ) => {
            if ( tokenState === "" ) return;

            setLoading( true );

            const response = await fetchGraphQl( deletePhotoId, { token: tokenState, photoId } );

            if ( response.errors ) {
                setLoading( false );
                setAlert({ message: "Failed to delete photo", type: 'error' });
            }

            setGallery( prev => prev.filter( photo => photo.id !== photoId ));

            setLoading( false );
        },

        uploadPhoto: async ( img: File ) => {
            if ( tokenState === "" ) return;

            setLoading( true );

            const form = new FormData();

            form.append("image", img);
            form.append("token", tokenState);
            form.append("eventId", eventId);

            const req = await fetch( getGalleryUploadApi(), { method: "POST", body: form });

            let result = await req.json();

            if ( result.error ) {
                setAlert({ message: "Failed to upload photo", type: 'error' });
                setLoading( false );
                return;
            }

            if ( result.url ) {
                setGallery( prev => [...prev, { id: result.id, url: result.url, dateUploaded: Date.now() }]);
                setLoading( false );
            }

            setAlert({ message: "Photo uploaded successfully", type:'success' });
        },

        galleryAlert: alert,
        setGalleryAlert: setAlert
    }
} 
