import "./index.scss";

import React, { useRef, useState, useEffect } from "react";
import { Outlet } from 'react-router-dom';

import EventDetailsNavBar from "../../Components/NavBar/DashBoard/Event";
import AuthtenticatedPage, { AuthenticatedPageRef } from "../../Components/AuthenticatedPage";
import EventDetailsContent, { EventDetailsMethods } from "./Details";
import { useEventDetailsPage } from "../../hooks/page/EventDetails";
import Loading from "../../Components/Loading";
import CustomAlert from "../../Components/CustomAlert";

export type EventDetailsContextType = ReturnType<typeof useEventDetailsPage>;

const EventDetails: React.FC = () => {
    const authPageRef = useRef<AuthenticatedPageRef>(null);
    const [ authReady, setAuthReady ] = useState<boolean>(false);

    const eventDetailsRef = useRef<EventDetailsMethods>(null);

    const [ active, setActive ] = useState<string>( "" );

    const { eventId, fetchData, loading, sales, eventDetails, uploadBanner, saveEventDetails, gallery, alert, closeAlert } = useEventDetailsPage();

    let contextProps = {
        eventId,
        loading,
        eventDetails,
        uploadBanner:  async ( img: File ) => await uploadBanner( 
            img,
            authPageRef.current?.getToken() || '',
        ),
        saveEventDetails,
        gallery,
        sales
    } as EventDetailsContextType;

    useEffect( () => {
        authPageRef.current && fetchData( authPageRef.current.getToken() || '' );
    }, [ authReady ]);

  return (
    <AuthtenticatedPage ref={authPageRef} setReady={ () => setAuthReady( true ) }>
        <div className='EventDetailsPage MainPage'>

            { loading && <Loading loading={ loading } /> }

            <CustomAlert
                message={alert?.message || ''}
                type={alert?.type}
                visible={!!alert}
                duration={10000}
                close={closeAlert}
            />

            <EventDetailsNavBar details={{ banner: eventDetails.banner, setActive: ( val ) => setActive( val ), title: eventDetails.title }} />
            
            <div className='content'>
                <div className='header'>
                    <div className='title'>Event { active.charAt(0).toUpperCase() + active.slice(1) }</div>

                    <div className='buttons'>
                        <div className='button'>Repeat Event</div>
                        <div className='button' onClick={ () => {
                            saveEventDetails( eventDetailsRef.current?.getEventDetails() || {}, authPageRef.current?.getToken() || '' )
                        }}>Save</div>
                    </div>
                </div>
                
                {
                    active === 'details' ? <EventDetailsContent context={ contextProps } ref={eventDetailsRef} /> : (
                        <Outlet context={ contextProps } />
                    )
                }
            </div>
        </div>
    </AuthtenticatedPage>
  );
};

export default EventDetails;
