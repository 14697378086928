import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from './Routes/Home';
import Sales from './Routes/Sales';
import SignUp from './Routes/SignUp';
import Register from './Routes/Register';
import Login, { LoginTokenRedirect } from './Routes/Login';
import DashBoard from './Routes/Dashboard';
import SignOut from './Routes/SignOut';
import Events from './Routes/Events';
import Billing from './Routes/Billing';

// Settings Import
import Settings from './Routes/Settings';
import { ProfileSettings } from './Routes/Settings/Profile';
import { OrganizerSettings } from './Routes/Settings/Org';
import { TeamsSettings } from './Routes/Settings/Teams';

import EventDetails from "./Routes/EventDetails";
import EventGallery from "./Routes/EventDetails/Gallery";
import EventAnalytics from "./Routes/EventDetails/Analytics";
import EventSales from "./Routes/EventDetails/Sales";
function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/sales" element={<Sales />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/register" element={<Register />} />

      <Route path="/login">
        <Route path="" element={<Login />} />
        <Route path=":token" element={<LoginTokenRedirect />} />
      </Route>
      
      <Route path="/sign/out" element={<SignOut />} />

      <Route path="/home" element={<DashBoard />} />

      <Route path="events">
        <Route path="" element={<Events />} />
        <Route path=":EventId" element={<EventDetails />}>
          <Route path="analytics" element={<EventAnalytics />} />
          <Route path="gallery" element={<EventGallery />} />
          <Route path="sales" element={<EventSales />} />
        </Route>
      </Route>
      
      <Route path="/billing" element={<Billing />} />

      <Route path='/settings' element={<Settings />}>
        <Route path='profile' element={<ProfileSettings />} />
        <Route path='org' element={<OrganizerSettings />} />
        <Route path='teams' element={<TeamsSettings />} />
      </Route>
    </Routes>
  );
}

export default App;
