import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useEventDetails } from './details';
import { useEventGallery } from './gallery';
import { useEventSales } from './sales';

export const useEventDetailsPage = () => {
    
    const { EventId } = useParams<{ EventId: string }>();
    
    const [alert, setAlert] = useState<{ message: string; type: 'success' | 'error' | 'info', alertType: "event" | "gallery" | "sales" } | null>(null);
    
    const { detailsLoading, eventDetails, saveEventDetails, fetchEventDetails, uploadBanner, eventAlert, eventSetAlert } = useEventDetails( EventId as string );
    const { galleryLoading, galleryLastIndex, galleryLoadMore, gallery, galleryPaginationRequest, deletePhoto, uploadPhoto, galleryAlert, setGalleryAlert } = useEventGallery( EventId as string );
    const { salesLoading, salesAlert, salesSetAlert, tickets, sales, fetchSalesDetails, modifyEventTickets, createEventTicket } = useEventSales( EventId as string ); 

    useEffect( () => {
        if ( eventAlert ) {
            setAlert( { ...eventAlert, alertType: "event" } );
        }
    }, [ eventAlert ]);

    useEffect( () => {
        if ( galleryAlert ) {
            setAlert({ ...galleryAlert, alertType: "gallery"  } );
        }
    }, [ galleryAlert ]);

    useEffect( () => {
        if ( salesAlert ) {
            setAlert({ ...salesAlert, alertType: "sales"  } );
        }
    }, [ salesAlert ]);

    return {
        eventId: EventId,
        loading: detailsLoading || galleryLoading || salesLoading,
        eventDetails,
        uploadBanner,
        saveEventDetails,
        gallery: {
            gallery,
            lastIndex: galleryLastIndex,
            loadMore: galleryLoadMore,
            paginationRequest: galleryPaginationRequest,
            deletePhoto, 
            uploadPhoto
        },

        sales: {
            tickets,
            sales,
            fetchSalesDetails,
            modifyEventTickets,
            createEventTicket
        },

        fetchData: async ( token: string ) => {
            if ( token === '' ) return;

            await fetchEventDetails( token );
            await galleryPaginationRequest( token );
            await fetchSalesDetails( token );
        },

        alert,
        closeAlert: () => {
            if ( alert?.alertType === "event" ) {
                eventSetAlert( null );
            }else if ( alert?.alertType === "gallery" ) {
                setGalleryAlert( null );
            }else if ( alert?.alertType === "sales" ) {
                salesSetAlert( null );
            }

            setAlert( null );
        }
    }
}
