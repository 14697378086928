import React, { useState, useEffect, useRef } from "react";

import QRCodeStyling from "qr-code-styling";
import { downloadImage } from "../../utils";

type EventQrCodeProps = {
  data: string;
  image: string;
  display: boolean;
  child?: React.ReactNode;
  upload?: { link: string, data: { [ key: string]: string } };
}

const EventQrCode: React.FC<EventQrCodeProps> = ({ data, image, display, upload, child }) => {
  const options = {
    data,
    margin: 4,
    qrOptions: {
      typeNumber: 0,
      mode: "Byte",
      errorCorrectionLevel: "Q",
    },
    imageOptions: { hideBackgroundDots: true, imageSize: 0.4, margin: 0 },
    dotsOptions: {
      type: "extra-rounded",
      color: "#f5f5f5",
      gradient: {
        type: "linear",
        rotation: 0.10471975511965977,
        colorStops: [
          { offset: 0, color: "#b10b0b" },
          { offset: 1, color: "#8e0693" },
        ],
      },
    },
    backgroundOptions: { color: "#ffffff", gradient: undefined },
    image,
    dotsOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#6a1a4c",
        color2: "#6a1a4c",
        rotation: "0",
      },
    },
    cornersSquareOptions: {
      type: "extra-rounded",
      color: "#000000",
      gradient: {
        type: "linear",
        rotation: 0.017453292519943295,
        colorStops: [
          { offset: 0, color: "#d11a1a" },
          { offset: 1, color: "#d00bb6" },
        ],
      },
    },
    cornersSquareOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000000",
        color2: "#000000",
        rotation: "0",
      },
    },
    cornersDotOptions: {
      type: "dot",
      color: "#ff0a0a",
      gradient: {
        type: "linear",
        rotation: 0,
        colorStops: [
          { offset: 0, color: "#c01111" },
          { offset: 1, color: "#6f098b" },
        ],
      },
    },
    cornersDotOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000000",
        color2: "#000000",
        rotation: "0",
      },
    },
    backgroundOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#ffffff",
        color2: "#ffffff",
        rotation: "0",
      },
    }
  };
  const [qrCode] = useState<QRCodeStyling>(new QRCodeStyling(options as any));
  
  const [ dataUri, setDataUri ] = useState<string>();

  const ref = useRef<HTMLDivElement>(null);

  const qrDataUri = async () => {
    const blob = await qrCode.getRawData('png');

    const form = new FormData();

    if ( blob && upload ) {
      form.append('image', blob as Blob );

      let keys = Object.keys( upload.data );
      
      for ( let i = 0; i < keys.length; i++ ) {
        let d = keys[i];
        form.append( d, upload.data[ d ] );
      }

      const req = await fetch( upload.link, {
        method: 'POST',
        body: form
      });
    }
    
  }

  useEffect(() => {
    if (ref.current) {
      qrCode.append(ref.current);
    }

  }, [qrCode, ref]);


  const downloadCanvas = async () => {

    if ( !ref.current && !qrCode ) return false;

    const blob = await qrCode.getRawData('png');
    
    if ( !blob ) return false;

    const url = URL.createObjectURL(blob as Blob);

    downloadImage( url, "CravingsInc-Event-QRCode.png")
  }

  return (
    <div className="qr-code-container box-shadow" style={{ display: display === true ? undefined : 'none' }}>
      <div ref={ref} />
      {
        <div className='child-content' onClick={ downloadCanvas }>
          { child }
        </div>
      }
    </div>
  );
};

export default EventQrCode;
