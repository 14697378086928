import './index.scss';

import React from 'react';

type PopUpProps = {
    active: boolean;
    setActive: ( val: boolean ) => void;
    children: React.ReactNode;
}

const PopUp: React.FC<PopUpProps> = ({ active, children, setActive }) => {

    return (
        <div className={`pop-up ${ active ? 'active' : '' }`}>
            <div className='background' onClick={ () => { setActive( false ); } }/>

            <div className='content'>
                {children}
            </div>
        </div>
    )
}

export default PopUp;
