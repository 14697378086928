export const formatDate = ( currentDate : Date ) => {

    // Get the month, day, and year components
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because January is 0
    const day = String(currentDate.getDate()).padStart(2, '0');
    const year = String(currentDate.getFullYear()).slice(2); // Getting last two digits of the year

    // Concatenate the components with '/' delimiter
    return `${month}/${day}/${year}`;
}

export const formatShortDate = ( date: Date ) => {
  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
  };

  const currentYear = new Date().getFullYear();
  const year = date.getFullYear();

  if (year !== currentYear) {
    options.year = "numeric";
  }

  return date.toLocaleDateString("en-US", options);
}

export const formatEventDate = ( startDate: Date, endDate?: Date ) => {
  let daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  let startHour = startDate.getHours() % 12;
  startHour = startHour === 0 ? 12 : startHour;

  let startMinute = startDate.getMinutes();

  let startAMPM = startDate.getHours() >= 12 ? 'PM' : 'AM';

  let stringDate = `${daysOfWeek[ startDate.getDay() ]}, ${ months[ startDate.getMonth() ] } ${ startDate.getDate() } ${ startDate.getDay() < ( endDate?.getDay() || 0 ) ? `- ${ endDate!.getDate() } ` : ''}@ ${startHour}:${startMinute >= 10 ? `${startMinute}` : `0${startMinute}`} ${startAMPM}`

  if ( endDate ) {
    let endHour = endDate.getHours() % 12;
    endHour = endHour === 0 ? 12 : endHour;

    let endMinute = endDate.getMinutes();

    let endAMPM = endDate.getHours() >= 12 ? 'PM' : 'AM';

    stringDate += ` - ${endHour}:${endMinute >= 10 ? `${endMinute}` : `0${endMinute}`} ${endAMPM}`
  }

  return stringDate;
};

export const truncateText = ( text: string, maxLength: number = 250 ) => {
  if (text.length <= maxLength) {
    return text;
  }
  return text.substring(0, maxLength) + '...';
}

export const isMobileDevice = () => {
  return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export const formatNumber = (num: number) : string => {
  if (num >= 1_000_000_000) {
    return (num / 1_000_000_000).toFixed(1).replace(/\.0$/, '') + 'b';
  }

  if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'm';
  }

  if (num >= 1_000) {
    return (num / 1_000).toFixed(1).replace(/\.0$/, '') + 'k';
  }

  return num.toString();
}

export const downloadImage = (url: string, filename: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename || "download");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
