import './index.scss';

import React, { useState, useEffect } from 'react';
import { useNavigate , useLocation } from 'react-router-dom';
import Logo from '../../../Logo';
import ArrowLeft from '../../../svgs/ArrowLeft';
import Teams from '../../../svgs/Teams';

type SettingsActive = 'org' | 'teams' | 'profile';
export type UserTitle = "Admin" | "Member" | "Guest";

export type SettingsDashBoardNavBarUsersProps = {
    name: string;
    picture: string;
    banner: string;
    id: string;
    editable: boolean;
    uploadProfilePicture: ( img: File ) => Promise<void>;
    changeMemberPassword: () => void;
    editMember: ( member: {  name?: string, email?: string, phoneNumber?: string, profilePicture?: string } ) => void;
    saveOrganizerMemberSettings: () => void;
}

export type SettingsDashBoardNavBarOrgProps = {
    name: string;
    picture: string;
    banner: string;
    editable: boolean;
    editOrganizer: ( org: {  orgName?: string, email?: string, phoneNumber?: string, location?: string, profilePicture?: string, banner?: string } ) => void;
    saveOrganizerSettings: () => void;
    changeOrganizerPassword: () => void;
    uploadBanner: ( img: File ) => Promise<void>;
    uploadProfilePicture: ( img: File ) => Promise<void>;
}

export type SettingsDashBoardNavBarProps<P=any, O=any> = {
    profile: SettingsDashBoardNavBarUsersProps & P;

    organizer: SettingsDashBoardNavBarOrgProps & O;
}

type Props = {
    settings: SettingsDashBoardNavBarProps;
    setPage: ( page: SettingsActive ) => void;
}

const SettingsDashBoardNavBar: React.FC<Props> = ({ settings, setPage }) => {
    const navigate = useNavigate();
    const location = useLocation();

    // Determine the active page based on the current path
    const [active, setActive] = useState<SettingsActive>(() => {
        const pathParts = location.pathname.split("/");

        const p = (pathParts[pathParts.length - 1] as SettingsActive | "settings");

        if ( p !== 'settings' ) return p;
        else return "org"
    });

    const { profile, organizer } = settings;

    useEffect(() => {
        const pathParts = location.pathname.split("/");
        let newActive = (pathParts[pathParts.length - 1] as SettingsActive | "settings" );

        if ( newActive === 'settings' ) return navigate('org');

        setActive(newActive);
        setPage(newActive);
    }, [location.pathname, setPage]);

    return (
        <div className="navbar settings dashboard-nav">
            <Logo onClick={() => navigate("/home")} />

            <div className="underline" />

            <div className="menu-container">
                <div className="items-wrapper">
                    {/* Main Dashboard Button */}
                    <div
                        className="menu-item"
                        onClick={() => navigate("/home")}
                    >
                        <div className="icon-wrapper box-shadow">
                            <ArrowLeft className="back" />
                        </div>
                        <div className="menu-title">Main Dashboard</div>
                    </div>

                    {/* Organization Banner */}
                    <div className="banner-org">
                        <img src={organizer.banner} alt="org banner" className="box-shadow" />
                        <div className="orgName">You: {profile.name}</div>
                        <div className="orgName">Organizer: {organizer.name}</div>
                    </div>

                    {/* Profile Tab */}
                    {profile.visible && (
                        <div
                            className={`menu-item ${active === "profile" ? "active box-shadow" : ""}`}
                            onClick={() => navigate("/settings/profile")}
                        >
                            <img src={profile.picture} className="icon" alt="Profile" />
                            <div className="menu-title">Profile</div>
                        </div>
                    )}

                    {/* Organizer Tab */}
                    <div
                        className={`menu-item ${active === "org" ? "active box-shadow" : ""}`}
                        onClick={() => navigate("/settings/org")}
                    >
                        <img src={organizer.picture} className="icon" alt="Organizer" />
                        <div className="menu-title">Organizer</div>
                    </div>

                    {/* Teams Tab */}
                    <div
                        className={`menu-item ${active === "teams" ? "active box-shadow" : ""}`}
                        onClick={() => navigate("/settings/teams")}
                    >
                        <div className="icon-wrapper box-shadow">
                            <Teams className="teams" />
                        </div>
                        <div className="menu-title">Teams</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SettingsDashBoardNavBar;
