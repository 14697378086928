import "./index.scss";

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import Logo from "../../../Logo";
import ArrowLeft from "../../../svgs/ArrowLeft";
import Analysis from "../../../svgs/Analysis";
import Sales from "../../../svgs/Sales";
import Pen from "../../../svgs/Pen";
import Gallery from "../../../svgs/Gallery";

type EventsDashBoardActive = "details" | "analytics" | "gallery" | "sales";
export type UserTitle = "Admin" | "Member" | "Guest";

export type EventsDashBoardNavBarProps = {
  setActive: ( val: EventsDashBoardActive ) => void;
  banner: string;
  title: string;
};

type Props = {
  details: EventsDashBoardNavBarProps;
};

const EventsDashBoardNavBar: React.FC<Props> = ({ details }) => {
  const navigate = useNavigate();

  const location = useLocation();

  const { EventId } = useParams();

  let pathName = location.pathname.split("/").pop();

  const allowedValues: EventsDashBoardActive[] = ["details", "analytics", "gallery", "sales"];

  const active = ["details", "analytics", "gallery", "sales"].includes( pathName as EventsDashBoardActive )
   ? pathName || "details"
    : allowedValues[0];

  const switchPage = ( page: EventsDashBoardActive | "" ) => navigate(`/events/${EventId}/${page}`);

  useEffect( () => details.setActive( active as EventsDashBoardActive ), [ active ]);

  return (
    <div className="navbar settings dashboard-nav">
      <Logo onClick={() => navigate("/home")} />

      <div className="underline" />

      <div className="menu-container">
        <div className="items-wrapper">
          <div
            className={`menu-item`}
            onClick={() => {
              navigate("/events");
            }}
          >
            <div className="icon-wrapper box-shadow">
              <ArrowLeft className="back" />
            </div>

            <div className="menu-title">Events Dashboard</div>
          </div>

          <div className="banner-org">
            <img src={details.banner} alt="template" className="box-shadow" />
            <div className="eventTitle">Event Name</div>
            <div className="eventName">{details.title}</div>
          </div>

          <div
            className={`menu-item ${
              active === "details" ? "active box-shadow" : ""
            }`}
            onClick={() => {
              switchPage("");
            }}
          >
            <div className="icon-wrapper box-shadow">
              <Pen />
            </div>
            <div className="menu-title">Details</div>
          </div>

          <div
            className={`menu-item ${
              active === "analytics" ? "active box-shadow" : ""
            }`}
            onClick={() => {
              switchPage("analytics");
            }}
          >
            <div className="icon-wrapper box-shadow">
              <Analysis stopColor1="white" stopColor2="white" className='analysis' />
            </div>
            <div className="menu-title">Analytics</div>
          </div>

          <div
            className={`menu-item ${
              active === "gallery" ? "active box-shadow" : ""
            }`}
            onClick={() => {
              switchPage("gallery");
            }}
          >
            <div className="icon-wrapper box-shadow">
              <Gallery className='gallery' />
            </div>
            <div className="menu-title">Gallery</div>
          </div>

          <div
            className={`menu-item ${
              active === "sales" ? "active box-shadow" : ""
            }`}
            onClick={() => {
              switchPage("sales");
            }}
          >
            <div className="icon-wrapper box-shadow">
              <Sales className="sales" />
            </div>
            <div className="menu-title">Sales</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsDashBoardNavBar;
