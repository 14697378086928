import React, { useEffect, useState } from 'react';
import './index.scss';

type AlertType = 'success' | 'error' | 'info';

interface CustomAlertProps {
    message: string;
    visible: boolean;
    close: () => void;
    type?: AlertType;
    duration?: number; // Duration before auto-close (0 = disabled)
    className?: string;
}

const CustomAlert: React.FC<CustomAlertProps> = ({
    message,
    type = 'info',
    duration = 10000,
    close,
    visible,
    className = '',
}) => {

    useEffect(() => {
        if (visible && duration > 0) {
            const timer = setTimeout(() => {
                close();
            }, duration);
            return () => clearTimeout(timer); // Cleanup timer on unmount
        }
    }, [visible, duration, close]);

    if (!visible) return <></>;

    return (
        <div
            className={`custom-alert box-shadow ${type} ${className}`}
            onClick={() => {
               
                close();
            }}
        >
            <p>{message}</p>
        </div>
    );
};

export default CustomAlert;
