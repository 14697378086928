import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { LOCAL_STORAGE_NAMESPACES, fetchGraphQl, organizerLogin, reLogin, OrganizerProfileInformation, getOrganizerProfile } from '../utils';


export const useLogin = ( ) => {
    const navigate = useNavigate();

    const [ loading, setLoading ] = useState<boolean>(false);
    const [ loggedIn, setLoggedIn ] = useState<boolean>(false);
    const [ error, setError ] = useState<string>("");
    const [alert, setAlert] = useState<{ message: string; type: 'error' | 'success' | 'info' } | null>(null);
    const [ token, setToken ] = useState<string | null>(null);
    const [ user, setUser ] = useState<OrganizerProfileInformation | null >(null);


    /**
     * Login user or organizer, and then saves the token and navigates to home if nav is true.
     * @param email organizer name
     * @param email email address for organizer or user
     * @param password password for organizer or user
     * @param nav whether or not to go to home page, and save token to local storage
     * @param alarm whether or not to alert when error is encountered
     * @returns 
     */
    const login = async ( orgName: string, email: string, password: string, nav: boolean = true, alarm: boolean = true ) => {
        setLoading( true );

        let res = await fetchGraphQl( organizerLogin, { orgName, email: email, password } );

        if ( res.errors ) {
            setLoggedIn( false );
            setLoading( false );
            setError( res.errors[0].message );

            if (alarm) {
                setAlert({
                    message: "Problem Loggin In, Please try again.",
                    type: 'error',
                });
            }

            return;
        }

        let serverToken: string = res.data.OrganizerLogIn;

        
        // Save locally both the token and type user
        localStorage.setItem( LOCAL_STORAGE_NAMESPACES.TOKEN, serverToken );
        

        setToken( serverToken );
        setLoggedIn( true );
        setLoading( false );

        if ( nav ) navigate('/home');
    }

    const signOut = () => {
        localStorage.removeItem( LOCAL_STORAGE_NAMESPACES.TOKEN );
    }

    const verifyLogin = async ( nav: boolean = true ) => {
        setLoading( true );

        let token = localStorage.getItem( LOCAL_STORAGE_NAMESPACES.TOKEN );
        
        if ( !token ) {
            setLoggedIn( false );
            setLoading( false );
            setError('User has not logged in.');

            if ( nav ) navigate(`/register`);

            return;
        }

        let resUser = await fetchGraphQl( getOrganizerProfile, { token } );

        if ( resUser.errors ) {
            setLoggedIn( false );
            setLoading( false );
            setError('User has not logged in.');

            if ( nav ) navigate(`/register`);

            return;
        }

        setUser( resUser.data.getOrganizerProfile as OrganizerProfileInformation )
       
        setToken( token );

        setLoggedIn( true );

        setLoading( false );
    }

    const reloginWithToken = async ( token: string, nav: boolean = true ) => {
        localStorage.setItem( LOCAL_STORAGE_NAMESPACES.TOKEN, token );

        await relogin( nav );
    }

    const relogin = async ( nav: boolean = true ) => {
        setLoading( true );

        let token = localStorage.getItem( LOCAL_STORAGE_NAMESPACES.TOKEN );
        
        if ( !token ) {
            setLoggedIn( false );
            setLoading( false );
            setError('User has not logged in.');

            if ( nav ) navigate(`/register`);

            return;
        }
        
        let res = await fetchGraphQl(reLogin, { token });

        if ( res.errors ) {
            
            setLoggedIn( false );
            setLoading( false );
            setError('User has not logged in.');

            if ( nav ) navigate(`/register`);

            return;
        }

        localStorage.setItem( LOCAL_STORAGE_NAMESPACES.TOKEN, res.data.relogin );

        let resUser = await fetchGraphQl( getOrganizerProfile, { token } );

        if ( resUser.errors ) {
            setLoggedIn( false );
            setLoading( false );
            setError('User has not logged in.');

            if ( nav ) navigate(`/register`);

            return;
        }

        setUser( resUser.data.getOrganizerProfile as OrganizerProfileInformation )
       
        setToken( res.data.relogin );

        setLoggedIn( true );

        setLoading( false );
    }

    return {
        loading,
        loggedIn,
        error,
        token,
        user,
        navigate,
        login,
        relogin,
        verifyLogin,
        reloginWithToken,
        signOut,
        setAlert,
        alert
    }
}
