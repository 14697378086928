import './index.scss';
import React, { useState, useEffect } from 'react';
import DropDown from '../DropDown/index';

// Define the type for the return structure
type DateProp = {
    hour: number;
    minute: number;
    amPM: "AM" | "PM";
};


type DateSelectorProps = {
    onDateChange: ( time: { hour: number, minute: number }) => void;
    defaultDate?: number;
    label?: string;
};

const TimeSelector: React.FC<DateSelectorProps> = ({ onDateChange, label, defaultDate }) => {
    
    let defaultDateObj = new Date(  defaultDate ?  defaultDate : new Date() );

    const [time, setDate] = useState<DateProp>({ 
        hour: defaultDateObj.getHours() > 12 ? defaultDateObj.getHours() - 12 : defaultDateObj.getHours(), 
        minute: defaultDateObj.getMinutes() + 1, 
        amPM: defaultDateObj.getHours() >= 12 ? "PM" : "AM" 
    });

    const handleChange = (part: string, selectedOption: { val: any; text: string }) => {
        setDate((prevDate) => ({
            ...prevDate,
            [part]: selectedOption.val,
        }));
    };

    useEffect( () => {
        onDateChange( { hour: ( time.amPM === "PM" ? 12 : 0 ) + time.hour , minute: time.minute } );
    }, [ time ])

    const hourOptions = Array.from({ length: 12 }, (_, i) => ({
        val: i,
        text: i.toString().padStart(2, '0'),
    }));

    const minuteOptions = Array.from({ length: 60 }, (_, i) => ({
        val: i,
        text: i.toString().padStart(2, '0'),
    }));

    const dropDownClass = {
        selector: 'date-selector',
        options: 'date-options',
        optContainers: 'date-options-container'
    }

    return (
        <div className='date-selector-container box-shadow'>
            <p className='label'>{label}</p>
            <div className='dropdowns'>
                <DropDown
                    options={hourOptions}
                    placeholder={hourOptions.find((op) => op.val === time.hour)?.text || ''}
                    onChange={(selectedOption) => handleChange('hour', selectedOption)}
                    dropDownClasses={ dropDownClass }
                />
                <DropDown
                    options={minuteOptions}
                    placeholder={minuteOptions.find((op) => op.val === time.minute)?.text || ''}
                    onChange={(selectedOption) => handleChange('minute', selectedOption)}
                    dropDownClasses={ dropDownClass }
                />
                <DropDown
                    options={ [ 'AM', 'PM' ].map( op => ({ val: op, text: op }) ) }
                    placeholder={ time.amPM }
                    onChange={(selectedOption) => handleChange('amPM', selectedOption)}
                    dropDownClasses={ dropDownClass }
                />
            </div>
        </div>
    );
}

export default TimeSelector;
