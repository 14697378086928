export const getApiUrl = () => {
    return `${getServerUrl()}graphql`;
}

export const getWSApiUrl = () => {
    return  `wss://${process.env.NODE_ENV === "production" ? "server.cravingsinc.us" : "localhost:3555"}/graphql`;
}

export const getImageUploadApi = () => {
    return `${getServerUrl()}user/upload/image`;
}

export const getGalleryUploadApi = () => {
    return `${getServerUrl()}event/upload/gallery`;
}

export const getEventBannerUploadApi = () => {
    return `${getServerUrl()}event/upload/banner`;
}

export const getOrganizerBannerUploadApi = () => {
    return `${getServerUrl()}organizer/upload/banner`;
}


export const getReviewProfileUploadApi = () => `${getServerUrl()}api/cart/review/picture`

export const getServerUrl = () => {
    return process.env.NODE_ENV === "production" ? "https://server.cravingsinc.us/" : "http://localhost:3555/";
}

export const getDomainUrl = () => window.location.origin + '/';

export const getUserPlatformDomainUrl = () => process.env.NODE_ENV !== "production" ? "http://www.localhost:3000/" : "https://www.cravingsinc.us/";
