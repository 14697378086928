import './index.scss';

import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';

import World from '../../../Components/svgs/World';
import Ticket from '../../../Components/svgs/Ticket';
import Lock from '../../../Components/svgs/Lock';
import Download from '../../../Components/svgs/Download';

import ImageUploader from '../../../Components/ImageUploader';
import DateSelector from '../../../Components/DateSelector';
import DropDownJsx from '../../../Components/DropDownJsx';
import EventQrCode from '../../../Components/EventQrCode';
import TimeSelector from '../../../Components/TimeSelector';

import { EventDetailsContextType } from '..';

import { getUserPlatformDomainUrl } from '../../../utils';

import { 
    BlockTypeSelect,
    BoldItalicUnderlineToggles,
    CreateLink,
    headingsPlugin, InsertTable, linkDialogPlugin, linkPlugin, listsPlugin, ListsToggle, markdownShortcutPlugin, MDXEditor, 
    MDXEditorMethods, quotePlugin, tablePlugin, thematicBreakPlugin, toolbarPlugin, 
    UndoRedo,
} from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css';

export type EventDetailsRef = {
    title: string,
    description: string,
    location: string,
    visibility: "Public" | "Private",
    eventDate: { startDate: number; endDate: number }
};

export type EventDetailsMethods = {
    getEventDetails: () => EventDetailsRef,
}

const EventDetails = forwardRef<EventDetailsMethods, { context: EventDetailsContextType }>(({ context }, ref ) => {
    const MDXRef = React.useRef<MDXEditorMethods>(null);

    const [ description, setDescription ] = useState<string>(context.eventDetails.description);
    const [ title, setTitle ] = useState<string>(context.eventDetails.title);
    const [ location, setLocation ] = useState<string>(context.eventDetails.location);
    const [ visibility, setVisibility ] = useState<"Public" | "Private">(context.eventDetails.visibility);
    const [ eventDate, setEventDate ] = useState<{ startDate: number; endDate: number }>(context.eventDetails.eventDate);

    const [ banner, setBanner ] = useState<string>(context.eventDetails.banner);

    useEffect( () => {
        if (context.eventDetails.description && MDXRef.current) {
            MDXRef.current.setMarkdown(context.eventDetails.description)
        }

        setTitle(context.eventDetails.title);
        setLocation(context.eventDetails.location);
        setVisibility(context.eventDetails.visibility);
        setEventDate(context.eventDetails.eventDate);
        setBanner(context.eventDetails.banner);

    }, [ context.eventDetails ]);

    useImperativeHandle(ref, () => ({
        getEventDetails: () => {
            return {
                title,
                description,
                location,
                visibility,
                eventDate,
                banner
            }
        }
    }));

    return (
        <div className='event-details-content'>
            <div className='form'>

                <div className='label-container box-shadow'>
                    <div className='label'>Title ( required )</div>
                    <input type='text' value={title} onChange={ e => setTitle( e.target.value ) } />
                </div>

                <div className='label-container box-shadow'>
                    {
                        context.eventDetails.description.length === 0 && <div className='label'>Description</div>
                    }

                    <MDXEditor
                        ref={MDXRef}
                        markdown={description}
                        onChange={ markdown => setDescription(markdown) }
                        plugins={[
                            toolbarPlugin({
                                toolbarClassName: 'my-classname',
                                toolbarContents: () => (
                                  <>
                                    {' '}
                                    <UndoRedo />
                                    <BoldItalicUnderlineToggles />
                                    <BlockTypeSelect />
                                    <CreateLink />
                                    <ListsToggle />
                                    <InsertTable />
                                  </>
                                )
                            }),
                            headingsPlugin(), 
                            listsPlugin(), 
                            quotePlugin(), 
                            tablePlugin(),
                            thematicBreakPlugin(),
                            linkPlugin(), 
                            linkDialogPlugin(),
                            markdownShortcutPlugin()
                        ]}
                        className="textarea"
                    />
                </div>

                <div className='label-container box-shadow'>
                    <div className='label'>Location</div>
                    <input type='text'  value={location} onChange={ e => setLocation( e.target.value ) } />
                </div>

                <div className='date-selectors'>
                    <DateSelector label="Start Date" defaultDate={eventDate.startDate} onDateChange={ date => {
                        setEventDate( prev => ({ ...prev, startDate: date.getTime() }))
                    } }/>
                    
                    <TimeSelector label="Start Time" defaultDate={eventDate.startDate} onDateChange={ date => {
                        let currentDate = new Date( eventDate.startDate );

                        currentDate.setHours( date.hour, date.minute );

                        setEventDate( prev => ({
                            ...prev, 
                            startDate: currentDate.getTime()  
                        }))
                    } }/>
                </div>

                <div className='date-selectors time'>
                    <DateSelector label="End Date" defaultDate={eventDate.endDate} onDateChange={ date => {
                        setEventDate( prev => ({ ...prev, endDate: date.getTime() }))
                    } }/>
                    
                    <TimeSelector label="End Time" defaultDate={eventDate.endDate} onDateChange={ date => {
                        let currentDate = new Date( eventDate.endDate );

                        currentDate.setHours( date.hour, date.minute );

                        setEventDate( prev => ({
                            ...prev, 
                            endDate: currentDate.getTime()  
                        }))
                    } }/>
                </div>

            </div>

            <div className='form'>
                <ImageUploader
                    preview={(
                        <img
                            src={context.eventDetails.banner}
                            alt="banner"
                            className="banner box-shadow"
                        />
                    )}
                    clickable={true}
                    handleImageUpload={ async ( img: File ) => { await context.uploadBanner( img ) } }
                >
                    <div className='upload-banner'>Upload New Banner</div>
                </ImageUploader>

                <div className='label-container sales form-2 box-shadow'>
                    <div className='label'>Ticket Sales</div>

                    <div className='content'>
                        <Ticket className='ticket' />
                        <div className='text'>{context.eventDetails.totalTicketSold} Tickets Sold</div>
                    </div>
                </div>

                <div className='label-container icon-dropdown form-2 box-shadow'>
                    <div className='label'>Visibility</div>

                    <DropDownJsx
                        options={
                            [ "Public", "Private" ].map(
                                ( opt ) => ({
                                    val: opt,
                                    text: opt,
                                    jsx: (
                                        <div className='icon-opts'>
                                            {
                                                opt === "Public" ?
                                                    <World className='icon world' /> : <Lock className='icon lock' />
                                            }

                                            <div className='text'>{opt}</div>
                                        </div>
                                    )
                                })
                            )
                        }

                        placeholder={
                            <div className='icon-opts'>
                                {
                                    visibility === "Public" ?
                                        <World className='icon world' /> : <Lock className='icon lock' />
                                }
                                <div className='text'>{visibility}</div>
                            </div>
                        }

                        onChange={
                            ( selected ) => {
                                setVisibility(selected.val);
                            }
                        }
                    />
                </div>

                <EventQrCode
                    data={`${getUserPlatformDomainUrl()}events/${context.eventId}`}
                    image="/logo-text.png"
                    display={true}
                    child={ <Download className='download-icon' />}
                />
            </div>
        </div>
    )
})

export default EventDetails;
