import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLogin } from '../../hooks';
import Loading from '../../Components/Loading';

export const LoginTokenRedirect = () => {
    let { token } = useParams();
    let { token: hookToken, reloginWithToken, loading } = useLogin();
    const navigate = useNavigate();

    useEffect( () => {
        (
            async () => {
                if ( token ) await reloginWithToken( token, true )
            }
        )()
    });

    useEffect( () => {
        if ( hookToken ) navigate('/home');
    }, [ hookToken ])

    return <Loading loading={loading} />;

}

